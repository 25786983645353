import { Stars } from '@components/common/icons/custom-icons'
import { FastlyImage } from '@components/common/image'

const COMMON_IMAGE_PATH = '/images/common/partners/'

type RatingsProps = {
  isShowLink?: boolean
  isDarkText?: boolean
}

const Ratings = ({ isShowLink = true, isDarkText = false }: RatingsProps) => {
  return (
    <div className="flex space-x-8 items-center text-white">
      <div>
        <FastlyImage
          src={`${COMMON_IMAGE_PATH}google-medium.png`}
          webpSrc={`${COMMON_IMAGE_PATH}google-medium.webp`}
          width={60}
          height={20}
        />
        <Stars className="my-2" />
        <p className={`${isDarkText && 'text-grey'}`}>4.8 / 5 stars</p>
      </div>
      {isShowLink ? (
        <a
          href="https://www.trustpilot.com/review/www.codingal.com"
          target="_blank"
          rel="noreferrer"
        >
          <FastlyImage
            src={`${COMMON_IMAGE_PATH}trust-pilot-half${
              isDarkText ? '-black' : ''
            }.png`}
            webpSrc={`${COMMON_IMAGE_PATH}trust-pilot-half${
              isDarkText ? '-black' : ''
            }.webp`}
            width={100}
            height={45}
          />
          <p className={`mt-2 ${isDarkText && 'text-grey'}`}>4.7 / 5 stars</p>
        </a>
      ) : (
        <div>
          <FastlyImage
            src={`${COMMON_IMAGE_PATH}trust-pilot-half${
              isDarkText ? '-black' : ''
            }.png`}
            webpSrc={`${COMMON_IMAGE_PATH}trust-pilot-half${
              isDarkText ? '-black' : ''
            }.webp`}
            width={100}
            height={45}
          />
          <p className={`${isDarkText && 'text-grey'}`}>4.7 / 5 stars</p>
        </div>
      )}
    </div>
  )
}
export default Ratings
