import { Trans } from '@lingui/macro'
import { Zap } from 'react-feather'

const PriceSlotsOverview = () => {
  return (
    <div className="flex justify-center lg:justify-start flex-wrap md:space-x-6">
      <div className="flex items-center text-center lg:text-left  text-sm lg:text-base">
        <p>
          <Zap className="text-orange mr-1 inline" size={18} />
          <Trans>
            Join 1000+ students who took a lesson in the last 24 hours!
          </Trans>
        </p>
      </div>
    </div>
  )
}
export default PriceSlotsOverview
