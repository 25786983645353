import { Trans } from '@lingui/macro'
import classNames from 'classnames'

type Props = {
  isTextWhite?: boolean
}
const StudentCountriesOverview = ({ isTextWhite = true }: Props) => {
  return (
    <div
      className={classNames('flex justify-center space-x-8 lg:justify-start', {
        'text-white': isTextWhite,
        'text-grey': !isTextWhite,
      })}
    >
      <div className="flex flex-col">
        <span className="inline-block text-xl font-700">
          <Trans>500,000+</Trans>
        </span>
        <p className="relative inline-block">
          <Trans>Students</Trans>
          <br />
          <span
            className="
              absolute bottom-0 inline-block w-6 border-b text-grey"
          />
        </p>
      </div>
      <div className="flex flex-col w-24 md:w-auto">
        <span className="inline-block text-xl font-700">
          <Trans>70+</Trans>
        </span>
        <p className="relative inline-block">
          <Trans>Countries</Trans>
          <br />
          <span
            className="
              absolute bottom-0 inline-block w-6 border-b text-grey"
          />
        </p>
      </div>
    </div>
  )
}
export default StudentCountriesOverview
