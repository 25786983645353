import Button from '@components/atoms/button'
import BookFreeClassBtn from '@components/common/book-free-class-btn'
import { FastlyImage } from '@components/common/image'
import { t, Trans } from '@lingui/macro'

type Props = {
  isInPaymentPartnersPage?: boolean
  isCodingCampPage?: boolean
  shouldScrollToTop?: boolean
  urlQuery?: string
}
const MoneyBackGuarantee = ({
  isInPaymentPartnersPage = false,
  isCodingCampPage,
  shouldScrollToTop,
  urlQuery,
}: Props) => {
  return (
    <div className="w-full px-5 md:px-3 pb-8 overflow-hidden lg:px-0 lg:p-0 lg:py-16">
      <h2
        id="curriculum"
        className="px-5 pb-3 text-2xl leading-tight text-center text-grey lg:text-4xl font-700"
      >
        <Trans id="homepage.guarantee.heading">
          You are in <span className="underlined-text">safe hands</span> at
          Codingal
        </Trans>
      </h2>
      <p className="text-xl leading-tight lg:mx-auto px-5 pb-8 text-center text-grey">
        <Trans>
          Like thousands of other parents who have enrolled their kids and teens
        </Trans>
      </p>
      <div className="border flex flex-wrap justify-center border-blue-100 max-w-6xl mx-auto">
        <div className="p-5 w-full lg:w-1/4 flex justify-center align-middle lg:border-r border-blue-100 bg-white">
          <FastlyImage
            src="/images/home/money-guarantee.png"
            webpSrc="/images/home/money-guarantee.webp"
            width={168}
            height={202}
            objectFit="contain"
            alt={t`Money back guarantee`}
          />
        </div>
        <div className="px-3 lg:px-8 py-8 w-full lg:w-3/4 text-lg lg:text-xl">
          <p className="my-4">
            <Trans>
              At Codingal, we are passionate about customer satisfaction.
            </Trans>
          </p>
          <p className="my-4">
            <Trans>
              Therefore, we have a 100% moneyback guarantee policy. If we do not
              meet your expectations, you have the right to cancel your purchase
              and get your money back. It&apos;s as simple as that.
            </Trans>
          </p>
          <p className="my-4">
            <Trans>
              If at any point, you feel that you wish to discontinue using
              Codingal, please email us at{' '}
              <a
                href="mailto:billing@codingal.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-200 hover:underline"
              >
                billing@codingal.com
              </a>{' '}
              and we will immediately process your refund for the remaining
              lessons/classes, no questions asked.
            </Trans>
          </p>
        </div>
      </div>
      <div className="mt-8 text-center mx-auto px-5 md:px-0 w-full lg:w-auto lg:px-0">
        {!isInPaymentPartnersPage && !isCodingCampPage && (
          <BookFreeClassBtn
            size="xl"
            shouldScrollToTop={shouldScrollToTop}
            query={urlQuery}
          />
        )}
        {isInPaymentPartnersPage && (
          <div className="flex flex-col space-y-4 lg:space-y-0 lg:flex-row lg:space-x-5 justify-center">
            <a
              href="https://www.codingal.com/payment-policy/"
              target="_blank"
              rel="noreferrer"
            >
              <Button className="w-full" variant="outline" size="xl">
                View payment policy
              </Button>
            </a>
            <a
              href="https://www.codingal.com/refund-policy/"
              target="_blank"
              rel="noreferrer"
            >
              <Button className="w-full" variant="outline" size="xl">
                View refund policy
              </Button>
            </a>
          </div>
        )}
      </div>
      {isCodingCampPage && (
        <div className="flex justify-center pt-8 md:pt-16">
          {' '}
          <a
            className="text-center btn btn-orange w-full md:w-auto py-3 text-lg"
            href="#courses"
          >
            Claim your spot now
          </a>
        </div>
      )}
    </div>
  )
}

export default MoneyBackGuarantee
