import { FastlyImage } from '@components/common/image'
import { Trans } from '@lingui/macro'

type codingCertificateProps = {
  title?: string | JSX.Element
  description?: string | JSX.Element
}

const CodingCertificate = ({ title, description }: codingCertificateProps) => {
  return (
    <div className="container mx-auto flex flex-col space-y-10 pb-20 text-grey px-5 relative">
      <FastlyImage
        src="/images/home/round-arrow.png"
        alt="Round arrow"
        webpSrc="/images/home/round-arrow.webp"
        width={100}
        height={100}
        className="hidden lg:block absolute top-5 left-0"
      />
      <FastlyImage
        src="/images/home/certificate.png"
        alt="Round arrow"
        webpSrc="/images/home/certificate.webp"
        width={100}
        height={100}
        className="hidden lg:block absolute top-5 right-0"
      />
      <h2 className="text-2xl lg:text-4xl font-600 lg:mb-3 text-center">
        {title ? (
          title
        ) : (
          <Trans id="home.coding-certificate.title">
            Earn <span className="yellow-underline">coding certifications</span>{' '}
            that make you stand out
          </Trans>
        )}
      </h2>
      <div className="flex flex-col items-center lg:flex-row gap-y-10 lg:gap-y-0 lg:gap-x-10 justify-between mb-12">
        <FastlyImage
          src="/images/home/student-certificate.png"
          alt="Coding Certificate"
          webpSrc="/images/home/student-certificate.webp"
          width={438}
          height={375}
        />

        <p className="text-lg lg:text-xl w-full lg:w-3/5 text-center lg:text-left">
          {description ? (
            description
          ) : (
            <Trans id="home.coding-certificate.description">
              Our young learners from grades 1-12 earn STEM.org-accredited
              coding certificates that celebrate their achievements while
              unlocking special badges and leaderboard rankings, making
              programming a fun and rewarding experience. Our curriculum allows
              students to earn a variety of certifications, including
              specializations like Young Scratch Developer, Young Python
              Programmer, Young Web Developer, Young AI Programmer, Advanced AI
              Programmer, Java Developer, AP Computer Science A Champion, and
              Coding Champion. For those looking to dive deeper, students can
              enroll in advanced courses and earn certifications such as Coding
              Grandmaster and Coding Prodigy. These certifications are easy to
              download and share, are STEM.org accredited, and can be added to
              your child&apos;s learning portfolio for sharing with the world
              and on social media.
            </Trans>
          )}
        </p>
      </div>
    </div>
  )
}

export default CodingCertificate
